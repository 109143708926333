import { Item } from './Item';

/**
 * group items
 * @type {Object}
 */
export const ItemGroup = {
  Keys: [Item.SafeKey, Item.CouchKey, Item.VaseKey],
  Flyers: [
    Item.FlyerEmpliciti,
    Item.FlyerImnovid,
    Item.FlyerReblozyl,
    Item.FlyerRevlimid,
    Item.FlyerInrebic,
    Item.FlyerRevlimidErstlinie,
    Item.FlyerOnu,
  ],
};
