import { Item } from './Item';
import { ItemGroup } from './ItemGroup';
import { View } from './View';
import { Callout } from './Callout';
import { Sound } from './Sound';
import SafeKey from '@img/keys/safe.svg';
import VaseKey from '@img/keys/vase.svg';
import CouchKey from '@img/keys/couch.svg';
import SafeKeyNotFound from '@img/keys/safe-notfound.svg';
import VaseKeyNotFound from '@img/keys/vase-notfound.svg';
import CouchKeyNotFound from '@img/keys/couch-notfound.svg';
import FlyerEmpliciti from '@img/flyers/flyer_empliciti-imnovid.jpg';
import FlyerImnovid from '@img/flyers/flyer_imnovid.jpg';
import FlyerInrebic from '@img/flyers/flyer_inrebic.jpg';
import FlyerReblozyl from '@img/flyers/flyer_reblozyl.jpg';
import FlyerRevlimid from '@img/flyers/flyer_revlimid.jpg';
import FlyerRevlimidErstlinie from '@img/flyers/flyer_revlimid_erstlinie.jpg';
import FlyerOnu from '@img/flyers/flyer_onu.jpg';

const keyUsedSfx = (used: Item[]) =>
  ItemGroup.Keys.filter((key) => used.includes(key)).length === 3
    ? Sound.Unlock
    : Sound.Locked;

/**
 * links items to views, sfx, callouts etc.
 */
export const ItemMeta: {
  [key in Item]?: {
    triggersView?: View;
    usedSfx?:
      | Sound.Win
      | Sound.Unlock
      | Sound.Locked
      | ((used: Item[]) => Sound.Win | Sound.Unlock | Sound.Locked);
    foundSfx?:
      | Sound.Success
      | Sound.Paper
      | ((found: Item[]) => Sound.Success | Sound.Paper);
    foundCallout?: Callout | Callout[];
    imageSrc?: string | [string, string];
  };
} = {
  [Item.Letter]: {
    triggersView: View.Letter,
    foundSfx: Sound.Paper,
    foundCallout: [Callout.Letter, Callout.Greenhouse],
  },

  // GAMES

  [Item.Safe]: {
    triggersView: View.SafeGame,
  },

  [Item.Couch]: {
    triggersView: View.CouchGame,
  },

  [Item.Vase]: {
    triggersView: View.VaseGame,
  },

  // KEYS

  [Item.SafeKey]: {
    usedSfx: keyUsedSfx,
    foundSfx: Sound.Success,
    foundCallout: Callout.Greenhouse,
    imageSrc: [SafeKey, SafeKeyNotFound],
  },

  [Item.CouchKey]: {
    usedSfx: keyUsedSfx,
    foundSfx: Sound.Success,
    foundCallout: Callout.Greenhouse,
    imageSrc: [CouchKey, CouchKeyNotFound],
  },

  [Item.VaseKey]: {
    usedSfx: keyUsedSfx,
    foundSfx: Sound.Success,
    foundCallout: Callout.Greenhouse,
    imageSrc: [VaseKey, VaseKeyNotFound],
  },

  // FLYERS

  [Item.FlyerEmpliciti]: {
    triggersView: View.FlyerEmpliciti,
    foundSfx: Sound.Paper,
    foundCallout: Callout.Flyer,
    imageSrc: FlyerEmpliciti,
  },

  [Item.FlyerImnovid]: {
    triggersView: View.FlyerImnovid,
    foundSfx: Sound.Paper,
    foundCallout: Callout.Flyer,
    imageSrc: FlyerImnovid,
  },

  [Item.FlyerReblozyl]: {
    triggersView: View.FlyerReblozyl,
    foundSfx: Sound.Paper,
    foundCallout: Callout.Flyer,
    imageSrc: FlyerReblozyl,
  },

  [Item.FlyerRevlimid]: {
    triggersView: View.FlyerRevlimid,
    foundSfx: Sound.Paper,
    foundCallout: Callout.Flyer,
    imageSrc: FlyerRevlimid,
  },

  [Item.FlyerInrebic]: {
    triggersView: View.FlyerInrebic,
    foundSfx: Sound.Paper,
    foundCallout: Callout.Flyer,
    imageSrc: FlyerInrebic,
  },

  [Item.FlyerRevlimidErstlinie]: {
    triggersView: View.FlyerRevlimidErstlinie,
    foundSfx: Sound.Paper,
    foundCallout: Callout.Flyer,
    imageSrc: FlyerRevlimidErstlinie,
  },

  [Item.FlyerOnu]: {
    triggersView: View.FlyerOnu,
    foundSfx: Sound.Paper,
    foundCallout: Callout.Flyer,
    imageSrc: FlyerOnu,
  },

  // FINAL DOOR
  [Item.GreenhouseDoor]: {
    usedSfx: Sound.Win,
  },
};
