import { useState, useCallback, memo } from 'react';
import classNames from 'classnames';
import { useUnity } from '@lib/unity';
import {
  useGoToView,
  View,
  useHandleObjectClick,
  useSetLoaded,
} from '@lib/state';

const Stage = memo(() => {
  const setLoaded = useSetLoaded();
  const [cursor, setCursor] = useState('default');
  const goToWelcome = useGoToView(View.Welcome);
  const handleObjectClick = useHandleObjectClick();

  // attach listeners when unity has loaded
  const handleLoad = useCallback(
    (instance, unityBus) => {
      // react to mouseovers within unity
      instance.on('cursorChange', setCursor);

      // react to object clicks
      instance.on('objectClick', handleObjectClick);

      // listen to events coming in
      unityBus.on('highlightInteractables', () => {
        instance.call('highlightInteractables');
      });

      // stop loading
      goToWelcome();
    },
    [goToWelcome, handleObjectClick]
  );

  // initialize unity
  const stageRef = useUnity(
    `${process.env.PUBLIC_URL}/static/Build/build.json`,
    handleLoad,
    setLoaded
  );

  return (
    <div
      className={classNames('stage', {
        'stage--pointer': cursor === 'pointer',
      })}
      ref={stageRef}
    ></div>
  );
});

export default Stage;
