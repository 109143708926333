const Legal = () => {
  return (
    <div className='legal'>
      <a
        href='https://www.bms.com/de/imprint.html'
        className='legal__link'
        target='_blank'
        rel='noreferrer'
      >
        Impressum
      </a>
      <a
        href='https://www.bms.com/de/privacy-policy.html'
        className='legal__link'
        target='_blank'
        rel='noreferrer'
      >
        Datenschutz
      </a>
    </div>
  );
};

export default Legal;
