import { memo, useState, useCallback } from 'react';
import Hints from '@components/Hints';
import IconButton from '@components/IconButton';
import { Help } from '@components/Icon';
import Bubble from '@components/Bubble';
import {
  useView,
  View,
  exploreView,
  useNeedsHelp,
  useCallout,
  useResetElapsedWithoutProgress,
} from '@lib/state';
import { useUnityBus } from '@lib/unity';
import vaseSolution from '@img/vase/solution.svg';

const GlobalHints = memo(() => {
  const view = useView();

  // bubble state
  const [visible, setVisible] = useState(false);
  const dismiss = useCallback(() => setVisible(false), [setVisible]);
  const toggle = useCallback(() => setVisible((v) => !v), [setVisible]);

  // determine if callout needs to be shown
  const needsHelp = useNeedsHelp();
  const [callout] = useCallout();
  const showCallout = !callout && needsHelp;

  // reset time on dismiss
  const dismissCallout = useResetElapsedWithoutProgress();

  // call unity functions
  const unityBus = useUnityBus();

  // determine hints content
  let hints;
  switch (view) {
    case View.SafeGame:
      hints = (
        <Hints id={View.SafeGame}>
          <p>
            Es müssen insgesamt vier Zahlen in einer bestimmten Reihenfolge
            eingegeben werden. Drei Zahlen sind benannt, auf die letzte gibt es
            einen Hinweis.
          </p>
          <p>
            Die fehlende Zahl ist die niedrigste. Der erste und der dritte
            Hinweis erklären, welche die fehlende Zahl ist.
          </p>
          <p>
            Die 1 wird als Erstes eingegeben. Klicken Sie dazu die 1 im
            Zahlenfeld an. Die Farbe der letzten Zahl ist blau.
          </p>
          <p>
            Geben Sie die Kombination 1273 über das Nummernfeld auf dem
            Bildschirm ein.
          </p>
        </Hints>
      );
      break;

    case View.CouchGame:
      hints = (
        <Hints id={View.CouchGame}>
          <p>
            Bringen Sie die Bilder per „Drag and Drop“ in die richtige zeitliche
            Reihenfolge. Die Sitzdelle auf dem letzten Bild zeigt dann an, wo
            sich der Schlüssel befindet.
          </p>
          <p>
            Ob Gegenstände vorhanden sind oder fehlen, gibt Ihnen Hinweise auf
            den zeitlichen Verlauf.
          </p>
          <p>
            Suchen Sie nach Bildelementen, die sich verändern und bringen Sie
            sie in eine logische Reihenfolge. Beginnen Sie mit dem Bild, welches
            das Symbol eines Kreises enthält.
          </p>
          <p>
            Ordnen Sie die Bilder mithilfe der Symbole in der angegebenen
            Reihenfolge an: Kreis, Dreieck, Stern, Quadrat.
          </p>
        </Hints>
      );
      break;

    case View.VaseGame:
      hints = (
        <Hints id={View.VaseGame}>
          <p>Schlagen Sie eine der Vasen per Mausklick an.</p>
          <p>
            Schlagen Sie die anderen Vasen an und hören Sie sich die vorgegebene
            Melodie nochmal an.
          </p>
          <p>
            Zum Nachspielen der Melodie beginnen Sie mit der großen Vase ganz
            rechts.
          </p>
          <div>
            <p>Schlagen Sie die Vasen in der angegebenen Reihenfolge an.</p>
            <img src={vaseSolution} alt='' />
          </div>
        </Hints>
      );
      break;

    case exploreView:
      hints = (
        <Hints
          id='global'
          hasSolution={false}
          intro={
            <>
              <h5 className='hints__title'>Tipp</h5>
              <p>Auf dem Wohnzimmertisch finden Sie eine Nachricht.</p>
            </>
          }
          outro={
            <p>
              Lassen Sie sich alle Elemente, mit denen Sie interagieren können,{' '}
              <button
                className='link'
                onClick={() => {
                  unityBus.emit('highlightInteractables');
                }}
              >
                hier
              </button>{' '}
              anzeigen.
            </p>
          }
        >
          <p>
            Ihre Aufgabe ist es, den Schlüssel für das Gewächshaus zu finden.
            Lösen Sie drei Rätsel, die sich hinter Objekten im Raum verbergen.
            <br />
            <br />
            Schauen Sie sich unten im Wohnzimmer um. Objekte, mit denen Sie
            interagieren können, leuchten auf, wenn Sie mit der Maus
            darüberfahren. Um zu interagieren, müssen Sie evtl. näher an ein
            Objekt herantreten.
          </p>
          <p>Ein Schlüssel befindet sich hinter einem Sofakissen.</p>
          <p>Ein Schlüssel befindet sich in einer der Vasen.</p>
          <p>Ein Schlüssel befindet sich im Safe hinter einem Wandbild.</p>
        </Hints>
      );
      break;
  }

  return (
    <Bubble
      className={visible && hints ? 'bubble--hints' : 'bubble--callout'}
      content={
        visible && hints
          ? hints
          : 'Hier erhalten Sie Hilfe, wenn Sie nicht weiterkommen.'
      }
      handleClose={visible ? dismiss : showCallout ? dismissCallout : undefined}
      visible={visible || showCallout}
      floatOnTop
    >
      {hints ? (
        <IconButton onClick={toggle} Icon={Help} />
      ) : (
        <IconButton Icon={Help} disabled floatOnTop />
      )}
    </Bubble>
  );
});

export default GlobalHints;
