import { Emitter } from 'nanoevents';
import { UnityInstance } from '@lib/unity';

declare global {
  interface Window {
    UnityLoader: {
      instantiate(
        el: HTMLElement,
        buildUrl: string,
        params?: {
          onProgress?: (
            instance: NativeUnityInstance,
            progress: number
          ) => void;
        }
      ): NativeUnityInstance;
      Error: {
        handler: (error: Error) => void;
      };
    };
    UnityBridge: UnityBridge;
  }
}

/**
 * Bridge will be called by Unity directly.
 */
export default class UnityBridge {
  constructor(
    private readonly _instance: UnityInstance,
    private readonly _emitter: Emitter
  ) {
    return new Proxy(this, {
      get(target: UnityBridge, prop: keyof UnityBridge) {
        if (prop in target) {
          return target[prop];
        }

        return () => {
          if (process.env.NODE_ENV === 'development') {
            console.log(
              `[UnityBridge] tried to call ${prop}(), which is not implemented`
            );
          }
        };
      },
    });
  }

  /**
   * requests the current url query as parameter dict
   * @private
   */
  getUrlParameters() {
    this._instance.call(
      'urlParameters',
      Object.fromEntries(new URLSearchParams(window.location.search))
    );
  }

  /**
   * unity wants to change cursor to default
   * @private
   */
  setCursorToDefault() {
    this._emitter.emit('cursorChange', 'default');
  }

  /**
   * unity wants to change cursor to pointer
   * @private
   */
  setCursorToPointer() {
    this._emitter.emit('cursorChange', 'pointer');
  }

  /**
   * unity reports click on object
   * @param {string} objectId
   */
  objectClicked(objectId: string) {
    this._emitter.emit('objectClick', objectId);
  }
}
