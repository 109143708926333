/**
 * Items in the game
 */
export enum Item {
  Letter = 'letter',
  Safe = 'safe',
  Couch = 'couch',
  Vase = 'vases',

  // keys
  SafeKey = 'key:safe',
  CouchKey = 'key:couch',
  VaseKey = 'key:vase',

  // flyers
  FlyerEmpliciti = 'flyer:empliciti',
  FlyerImnovid = 'flyer:imnovid',
  FlyerReblozyl = 'flyer:reblozyl',
  FlyerRevlimid = 'flyer:revlimid',
  FlyerInrebic = 'flyer:inrebic',
  FlyerRevlimidErstlinie = 'flyer:revlimidErstlinie',
  FlyerOnu = 'flyer:onu',

  // final door
  GreenhouseDoor = 'door:greenhouse',
}
