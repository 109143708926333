import { memo } from 'react';
import { useFound, Item } from '@lib/state';
import NoteInrebicEmpty from '@img/notes/inrebic-empty.svg';
import NoteInrebic from '@img/notes/inrebic.svg';
import NoteImnovidEmpty from '@img/notes/imnovid-empty.svg';
import NoteImnovid from '@img/notes/imnovid.svg';
import NoteReblozylEmpty from '@img/notes/reblozyl-empty.svg';
import NoteReblozyl from '@img/notes/reblozyl.svg';
import NoteRevlimidEmpty from '@img/notes/revlimid-empty.svg';
import NoteRevlimid from '@img/notes/revlimid.svg';

const Notes = memo(() => {
  const found = useFound();

  return (
    <div className='safe__notes'>
      <img
        src={
          found.includes(Item.FlyerReblozyl) ? NoteReblozyl : NoteReblozylEmpty
        }
        alt={Item.FlyerReblozyl}
        className='safe__note'
      />
      <img
        src={found.includes(Item.FlyerInrebic) ? NoteInrebic : NoteInrebicEmpty}
        alt={Item.FlyerInrebic}
        className='safe__note'
      />
      <img
        src={
          found.includes(Item.FlyerRevlimid) ? NoteRevlimid : NoteRevlimidEmpty
        }
        alt={Item.FlyerRevlimid}
        className='safe__note'
      />
      <img
        src={found.includes(Item.FlyerImnovid) ? NoteImnovid : NoteImnovidEmpty}
        alt={Item.FlyerImnovid}
        className='safe__note'
      />
    </div>
  );
});

export default Notes;
