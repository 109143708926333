import { memo } from 'react';
import classNames from 'classnames';
import LogoImnovid from '@img/logos/Logo-Imnovid.svg';
import LogoInrebic from '@img/logos/INREBIC_Logo_dt_RGB.png';
import LogoEmpliciti from '@img/logos/Empliciti_NALogo_R_RGB.svg';
import LogoReblozyl from '@img/logos/Reblozyl-Logo.png';
import LogoRevlimid from '@img/logos/Revlimid_Logo_D_4c.svg';
type LogosProps = {
  className?: string;
};

const Logos = memo(({ className }: LogosProps) => {
  return (
    <div className={classNames('logos', className)}>
      <div className='logos__wrap'>
        <div className='logos__item'>
          <img src={LogoRevlimid} alt='' className='logos__logo' />
        </div>
        <div className='logos__item'>
          <img src={LogoImnovid} alt='' className='logos__logo' />
        </div>
        <div className='logos__item'>
          <img src={LogoEmpliciti} alt='' className='logos__logo' />
        </div>
        <div className='logos__item'>
          <img src={LogoReblozyl} alt='' className='logos__logo' />
        </div>
        <div className='logos__item'>
          <img src={LogoInrebic} alt='' className='logos__logo' />
        </div>
      </div>
    </div>
  );
});

export default Logos;
