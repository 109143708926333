import { Emitter, Unsubscribe, createNanoEvents } from 'nanoevents';
import { UnityBridge } from '@lib/unity';

export default class UnityInstance {
  private _unityInstance: NativeUnityInstance;
  private _emitter: Emitter;
  private _bridge: UnityBridge;

  constructor(
    el: HTMLElement,
    buildUrl: string,
    onLoad?: (unityInstance: UnityInstance) => void,
    onProgress?: (progress: number) => void
  ) {
    // instantiate event bus
    this._emitter = createNanoEvents();

    // provide public API for Unity to call
    this._bridge = new UnityBridge(this, this._emitter);
    window.UnityBridge = this._bridge;

    // instantiate unity
    this._unityInstance = window.UnityLoader.instantiate(el, buildUrl, {
      onProgress: (
        nativeUnityInstance: NativeUnityInstance,
        progress: number
      ) => {
        if (onProgress) {
          onProgress(progress);
        }

        if (progress === 1 && onLoad) {
          onLoad(this);
        }
      },
    });
  }

  /**
   * calls a unity function by name
   * @param {string}  methodName The name of the function that you want to call
   * @param {any}     param      The Parameter value -> can be object or value type
   */
  call(methodName: string, param?: any) {
    if (this._unityInstance) {
      this._unityInstance.SendMessage(
        '__JavaScriptBridge',
        'CallbackFromJS',
        `${methodName}@@${JSON.stringify(param)}@@-1`
      );
    } else {
      console.log('[UnityInstance] unity has not been instantiated');
    }
  }

  /**
   * Quit unity player
   * @param {Function} callback will be called after quitting
   */
  quit(callback?: Function) {
    if (this._unityInstance) {
      this._unityInstance.Quit(callback);
      this._emitter.events = {};
    } else {
      console.log('[UnityInstance] unity has not been instantiated');
    }
  }

  /**
   * listen for events raised in UnityBridge
   * @param {string}     event   name of the event
   * @param {Function}   handler callback function that gets parameters
   */
  on(event: string, handler: (...args: any) => void): Unsubscribe {
    return this._emitter.on(event, handler);
  }
}
