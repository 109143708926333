/**
 * available views
 */
export enum View {
  // prelude
  Loading = 'loading',
  Welcome = 'welcome',

  // main room
  Explore = 'explore',

  // intro letter
  Letter = 'letter',

  // games
  SafeGame = 'game:safe',
  CouchGame = 'game:couch',
  VaseGame = 'game:vase',

  // flyers
  FlyerOverview = 'flyer:all',
  FlyerEmpliciti = 'flyer:empliciti',
  FlyerImnovid = 'flyer:imnovid',
  FlyerReblozyl = 'flyer:reblozyl',
  FlyerRevlimid = 'flyer:revlimid',
  FlyerInrebic = 'flyer:inrebic',
  FlyerRevlimidErstlinie = 'flyer:revlimidErstlinie',
  FlyerOnu = 'flyer:onu',

  // greenhouse
  Greenhouse = 'greenhouse',

  // exit overlay
  Exit = 'exit',
}

/**
 * initial view
 */
export const initialView =
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_DEV_BUILD === 'true'
    ? View.Welcome
    : View.Loading;

/**
 * "default" explore view
 */
export const exploreView = View.Explore;
