import { memo, useMemo } from 'react';
import { useView, View, Item } from '@lib/state';
import { AnimatePresence } from 'framer-motion';
import Welcome from '@views/Welcome';
import Exit from '@views/Exit';
import SafeGame from '@views/SafeGame';
import CouchGame from '@views/CouchGame';
import VaseGame from '@views/VaseGame';
import Flyer from '@views/Flyer';
import Letter from '@views/Letter';
import Greenhouse from '@views/Greenhouse';

type RouteMap = { [K in View]: React.ReactNode };

/**
 * map views to components
 * each view must have a representation
 * @type {object}
 */
const routes: RouteMap = {
  [View.Loading]: <Welcome key='welcome' isLoading />,
  [View.Welcome]: <Welcome key='welcome' />,
  [View.Explore]: null,

  [View.Letter]: <Letter key='letter' />,

  [View.FlyerOverview]: <Flyer key='flyer' />,
  [View.FlyerEmpliciti]: <Flyer key='flyer' flyer={Item.FlyerEmpliciti} />,
  [View.FlyerImnovid]: <Flyer key='flyer' flyer={Item.FlyerImnovid} />,
  [View.FlyerReblozyl]: <Flyer key='flyer' flyer={Item.FlyerReblozyl} />,
  [View.FlyerRevlimid]: <Flyer key='flyer' flyer={Item.FlyerRevlimid} />,
  [View.FlyerInrebic]: <Flyer key='flyer' flyer={Item.FlyerInrebic} />,
  [View.FlyerRevlimidErstlinie]: (
    <Flyer key='flyer' flyer={Item.FlyerRevlimidErstlinie} />
  ),
  [View.FlyerOnu]: <Flyer key='flyer' flyer={Item.FlyerOnu} />,

  [View.SafeGame]: <SafeGame key='safe' />,
  [View.CouchGame]: <CouchGame key='couch' />,
  [View.VaseGame]: <VaseGame key='vase' />,

  [View.Greenhouse]: <Greenhouse key='greenhouse' />,

  [View.Exit]: <Exit key='exit' />,
};

const Router = memo(() => {
  const currentView = useView();

  const currentRoute = useMemo(() => {
    if (currentView in routes) {
      return routes[currentView];
    }

    return null;
  }, [currentView]);

  return <AnimatePresence>{currentRoute}</AnimatePresence>;
});

export default Router;
